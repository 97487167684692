html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
}
#root {
  width: 100%;
  height: 100%;
}

/* list-item on select event */
.on-select {
  background: rgba(36, 29, 29, 0.376) !important;
}
/* list select item */
.list-item:hover, .list-item-select {
  background-color: rgba(255, 255, 255, 0.137); 
}
