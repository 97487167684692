.administrator-title {
  margin: 10px;
}
.administrator-create-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}
.administrator-title-small {
  font-size: 1.7em;
  margin: 16px;
}
.administrator-drawer-icon {
  margin: 10px;
}
