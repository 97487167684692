.circle-dot-red {
  margin: 0px 5px;
  height: 15px;
  width: 15px;
  background-color: #e90808;
  border-radius: 50%;
  display: inline-block;
}
.circle-dot-green {
  margin: 0px 5px;
  height: 15px;
  width: 15px;
  background-color: #13c719;
  border-radius: 50%;
  display: inline-block;
}
