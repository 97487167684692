.app-bg {
  background: url('../../assets/images/final.png')  no-repeat center center;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  height: 200px;
}
.home-title {
  h1 {
    padding-top: 50px;
    text-align: center;
    font-size: 80px;
    font-weight: bold;
  }
  p {
    text-align: center;
    font-size: 1.3em;
  }
}
