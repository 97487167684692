.navbar-list {
  padding: 0px;
  .navbar-item {
    padding: 10px;
    color: rgb(218, 218, 218);
    font-weight: bold;
    display: inline-flex;
  }
  .icon-label {
    display: inline;
    padding-left: 15px;
    font-size: 1.2em;
    font-weight: bold;
  }
  .active {
    border-bottom-style: solid;
    color: rgb(255, 255, 255);
  }
  .navbar-item:hover {
    background-color: rgba(255, 255, 255, 0.171);
    color: rgb(255, 255, 255) !important;
  }
}
