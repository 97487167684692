.h-image-compoment {
  padding-bottom: 14px;
  .image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
    }
  }
}
