.model-title {
  h5 {
    margin: 20px 0 0 0 !important;
    text-transform: uppercase;
  }
  h1 {
    font-family: "Questrial";
    margin: 0px;
    font-size: 64px;
    text-transform: capitalize;
  }
  p {
    margin: 0 0 20px 0 !important;
    color: rgb(182, 182, 182);
  }
}
