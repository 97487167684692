body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Questrial' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Questrial";
  src: local("Questrial"), url("./assets/fonts/Questrial-Regular.ttf");
  font-weight: bold;
}
