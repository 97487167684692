.card-list-pro-band {
  margin: 20px;
  color: rgb(255, 255, 255);
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  // position: absolute;
}

.card-list-pro-band {
  .list-base {
    position: relative;
    width: 960px;
    height: 500px;
    margin-bottom: 20px;
    font-size: 1.2em;
    .background {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .color {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.464);
    }
  }
  
  .list-item {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    .image {
      flex: 1;
      margin: 0px;
      position: relative;
      .component-image {
        position: absolute;
        bottom: 0%;
        left: 50%;
       
        transform: translate(-50%, -20%);
      }
      .sigla {
        position: absolute;
        bottom: 0%;
        left: 50%;
        font-family: fantasy;
        transform: translate(-50%, -20%);
      }
    }
    .content {
      flex: 3;
      padding-top: 20px;
      padding-left: 20px;
      
      .content-fixed {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .title {
        margin-top: 20px;
        font-size: 2em;
        font-weight: bold;
      }
      .subtitle {
        color: rgba(253, 253, 253, 0.322);
      }
      .detail-title {
        position: absolute;
        width: 100%;
      }
      .detail-detail {
        // text-align: center;
        position: absolute;
        bottom: 5%;
        width: 100%;
        display: flex;
        flex-direction: row;
        // text-align: center;
        .detail-item {
          flex: 1;
        }
        .title-detail {
        }
        .title-value {
          color: rgba(253, 253, 253, 0.322);
          font-size: 1.5em;
        }
      }
    }
  }
  .list-item:hover {
    background-color: rgba(255, 255, 255, 0.082); 
  }
}
