.tournament {
  background: url('../../../assets/images/final.png')  no-repeat center center fixed;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  width: 100%;
  height: 100%;

  .tor-back {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #000000d4;
  }
  .tor-item-links {
    position: absolute;
    top: 1%;
    left: 1%;
    // width: 200px;
    // height: 200px;
    .links {
      color: #ffffffd4;
    }
  }
  .tor-item-champion {
    position: absolute;
    top: 10%;
    left: 40%;
    width: 20%;
    height: 35%;

    .champeon {
      width: 100%;
      height: 100%;
      display: block;
    }
    .image-title {
      text-align: center;
      font-size: 50px;
      font-family: fantasy;
      color: #ffc900;
    }
    .image-cup {
      background: url('../../../assets/images/copa-gold.png')  no-repeat center center;
      background-repeat: no-repeat;
      object-fit: cover;
      background-size: contain;
      width: 50%;
      height: 100%;
      float: right;
    }
    .image-school {
      background-repeat: no-repeat;
      object-fit: cover;
      background-size: contain;
      width: 50%;
      height: 100%;
      float: right;
    }
    .links {
      color: #ffffffd4;
    }
  }
  .tor-item {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.518);
  }
  .tor-box {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid rgb(255, 27, 124);
    .vs {
      position: absolute;
      top: 40%;
      width: 100%;
      text-align: center;
      font-style: italic;
      font-family: fantasy;
      color: #ff0051;
      font-size: 25px;
    }
  }
  .tor-box-item {
    position: absolute;
    // border: 1px solid rgb(255, 27, 124);
    // width: 100px;
    // height: 100px;
    .school {
      width: 100%;
      height: 100%;
      // display: inline;
      position: relative;
      .title {
        position: absolute;
        margin: auto;
        // background-color: rgba(52, 52, 52, 0.457);
        // font-weight: bold;
        // color: rgb(252, 192, 12);
        color: #ff0051;
        flex-direction: row;
        height: 20%;
        font-size: 0.8em;
        width: 100%;
        height: 100%;
        font-family: fantasy;
        top: -19px;

        .title-item {
          text-align: center;
        }
      }
      .image {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      
    }
  }
  .box-line {
    position: absolute;
    border-top: 2px solid rgb(255, 27, 124);
  }
  .box-line-v {
    position: absolute;
    border-left: 2px solid rgb(255, 27, 124);
  }
}