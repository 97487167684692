.form-labels {
  .labels-field {
    font-size: 20px;
    padding: 10px;
    .field-label {
      font-weight: 700;
      font-size: 0.7em;
      line-height: 1em;
    }
    .field-input {
      font-size: 1.5em;
      color: rgb(203, 203, 203);
      line-height: 1em;
    }
  }
}
