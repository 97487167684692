.element-dimmer {
  color: #ffffff;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.50);
  font-size: 1.5em;
}
.element-dimmer-hide {
  visibility: hidden;
}
.element-dimmer-show {
  visibility: visible;
}
