.login-information {
  background-color: rgba(0, 0, 0, 0.315);
  margin-right: 12px;
  margin-right: 12px;
  border-radius: 19px;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  div {
    float: left;
    display: inherit;
  }
  img {
    width: 38px;
    height: 38px;
    border-radius: 19px;
  }
  h4 {
    display: inline;
    padding: 3px 12px;
    font-size: 22px;
  }
}
